import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom'

export default function AllHeroes() {

  const mainContainerStyle = {
    textAlign: 'center',
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  }

  const logoStyle = {
    width: '250px',
    marginTop: '3vh',
  }

  const iconsStyle = {
    width: '20vw',
  }
  
  return (
    <Container fluid={true} style={mainContainerStyle}>
      <Row xs='auto' style={{justifyContent: 'center', margin: 0}}>
        <Image style={logoStyle} src={require(`../images/logo.png`)} alt='Crypto Hero'/>
      </Row>
      <br></br>
      <br></br>
      <Row xs='auto' style={{padding: 0, justifyContent: 'center', margin: 0}}>
        <Col xs={8}>
          <Row style={{color: '#bbb', fontSize: '5vw', fontWeight: 600, textAlign: 'center', margin: 0}}>
            Operações encerradas
            <br></br>
            <br></br>
            Caso ainda possua fundos na plataforma, entre em contato através do e-mail abaixo
          </Row>
          <br></br>
        </Col>
      </Row>
      <Row>
        <Card style={{alignItems: 'center', border: 'none', backgroundColor: 'transparent'}}>
          <Link to={{ pathname: 'mailto:suporte@cryptohero.com.br' }} target='_blank'>
            <Card.Img style={iconsStyle} src={require(`../images/email-icon.png`)} alt='Crypto Hero'/>
          </Link>
          <Card.Title style={{fontSize: '2.5vw', textAlign: 'center', paddingTop: '3%'}}>
            suporte@cryptohero.com.br
          </Card.Title>
        </Card>
      </Row>
    </Container>
  )
}